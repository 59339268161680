import { noop } from '../../../../utils/constants';
import { Table } from '@idm/ui-components';

import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

class SearchResult extends Component {
  static propTypes = {
    searchType: PropTypes.string,
    currentPageData: PropTypes.arrayOf(PropTypes.object.isRequired),
    currentPageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    searchResultsSize: PropTypes.number,
    isWaitingForResults: PropTypes.bool.isRequired,
    onClickRow: PropTypes.func.isRequired,
    onPageChange: PropTypes.func,
    searchError: PropTypes.bool,
    selectedRow: PropTypes.object.isRequired,
  };

  static defaultProps = {
    searchType: 'addClient',
    currentPageNumber: 1,
    pageSize: 5,
    currentPageData: [],
    onPageChange: noop,
    searchResultsSize: 5,
    searchError: false,
  };

  static uniqueKeyMap = {
    addClient: 'addClient',
  };

  static recordUrlMap = {
    clientSwicth: 'addClient',
  };

  static searchResultsColumnsMap = {
    addClient: [
      { title: 'Client Name', dataIndex: 'name', width: 250 },
      { title: 'Client ID', dataIndex: '_id', width: 250 },
      { title: 'Purpose', dataIndex: 'purpose', width: 30 },
    ],
  };

  handlePageNumberChange = ({ numberOfPageJump }) => {
    const { currentPageNumber, onPageChange } = this.props;

    onPageChange(currentPageNumber + numberOfPageJump);
  };

  searchResultsSkeletonColumn = [
    {
      dataIndex: 'key',
      render: (key) => {
        return (
          <div key={key} className="table-skeleton">
            <Skeleton width="100%" height={30} />
          </div>
        );
      },
    },
  ];

  attachKeys = (data) => data.map((datum, index) => ({ key: `${index}`, ...datum }));

  render() {
    const {
      currentPageData,
      pageSize,
      currentPageNumber,
      isWaitingForResults,
      searchType,
      searchResultsSize,
      searchError,
      selectedRow,
    } = this.props;

    const columnsValues = isWaitingForResults
      ? this.searchResultsSkeletonColumn
      : SearchResult.searchResultsColumnsMap[searchType];

    const skeletonTableData = this.attachKeys(new Array(pageSize).fill({}));

    const tableData = currentPageData.map((datum, index) => ({
      key: `${index}`,
      ...datum,
    }));

    const { onClickRow } = this.props;

    const resultsText = isWaitingForResults ? 'Loading...' : 'No results';

    const textDisplay = searchError
      ? 'Unexpected error occurred while searching by client name'
      : resultsText;

    return (
      <section className="search-results">
        <Table
          emptyText={textDisplay}
          columns={columnsValues}
          data={isWaitingForResults && !searchError ? skeletonTableData : tableData}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          supportIdmPaginationModel={true}
          onPageNumberChange={this.handlePageNumberChange}
          isPreviousPageAvailable={currentPageNumber > 1 && !isWaitingForResults}
          isNextPageAvailable={
            pageSize * currentPageNumber < searchResultsSize && !isWaitingForResults
          }
          onClickRow={onClickRow}
          className="fixed-navigator .icc-table"
          supportRowSelection={tableData.length > 0}
          selectedRowKeys={selectedRow ? [selectedRow.key] : []}
          rowSelectionType="radio"
        />
      </section>
    );
  }
}
export default SearchResult;
