const changeUsernameActionTypes = {
  RESET_STATE: 'RESET_STATE',
  LOAD_SUCCESS: 'LOAD_SUCCESS',
  LOAD_FAILURE: 'LOAD_FAILURE',
  LOAD_REQUEST: 'LOAD_REQUEST',
  VALIDATION_ERROR: 'GET_USERNAME_VALIDATION_FAILURE',
  GET_USERNAME_REQUEST: 'GET_USERNAME_REQUEST',
  GET_USERNAME_SUCCESS: 'GET_USERNAME_SUCCESS',
  GET_USERNAME_ERROR: 'GET_USERNAME_ERROR',
  CHECK_USERNAME_UNIQUE_REQUEST: 'CHECK_USERNAME_UNIQUE_REQUEST',
  CHECK_USERNAME_UNIQUE_SUCCESS: 'CHECK_USERNAME_UNIQUE_SUCCESS',
  CHECK_USERNAME_UNIQUE_ERROR: 'CHECK_USERNAME_UNIQUE_ERROR',
  CHANGE_USERNAME_ERROR: 'CHANGE_USERNAME_ERROR',
  CHANGE_USERNAME_SUCCESS: 'CHANGE_USERNAME_SUCCESS',
  CHANGE_USERNAME_REQUEST: 'CHANGE_USERNAME_REQUEST',
  REVIEW_USERNAME_ERROR: 'REVIEW_USERNAME_ERROR',
  REVIEW_USERNAME_SUCCESS: 'REVIEW_USERNAME_SUCCESS',
  REVIEW_USERNAME_REQUEST: 'REVIEW_USERNAME_REQUEST',
  RESET_ERROR: 'RESET_ERROR',
};

export default changeUsernameActionTypes;
