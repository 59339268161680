import HttpClient from '../../../utils/http/HttpClient';
import getConstants from '../../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;
const identityOrchestratorUrlV1 = constants.serviceBaseUrls.identityOrchestratorV1;

class ChangeUserNameDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
    this.httpClientV1 = HttpClient.createInstance({
      baseURL: identityOrchestratorUrlV1,
    });
  }

  async getConflictingUsernames() {
    const { data: response } = await this.httpClient.get('/persons/me/check-username-obligation');

    return response;
  }

  async updateUsername(payload) {
    await this.httpClient.post('/persons/me/update-username', payload);
  }

  async reviewUsername(payload) {
    await this.httpClient.post('/persons/me/review-username', payload);
  }

  async checkUsernameUnique(username) {
    const { data: response } = await this.httpClientV1.get(
      `/username/${username}/username-availability-check`
    );

    return response;
  }
}
export default new ChangeUserNameDatasource();
