/* eslint-disable react/sort-comp */
import { Flexbox, NotValidatedInput } from '@idm/ui-components';
import './styleBarGroup.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchBarGroup extends Component {
  static propTypes = {
    searchFilters: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    fetchSearchResults: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
  };

  timeoutId = null;

  timeoutInMs = 500;

  static defaultProps = {
    totalCount: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentSearchFilter: '',
    };
  }

  handleInputChange = (event, filterName) => {
    const searchPhrase = event.target.value;

    this.setState({
      [filterName]: searchPhrase,
    });

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.props.fetchSearchResults(searchPhrase);
    }, this.timeoutInMs);
  };

  renderSearchBar = (searchFilter) => {
    const { name: filterName, value: filterValue } = searchFilter;

    return (
      <div className="search-bar" key={searchFilter.name}>
        <NotValidatedInput
          autoComplete="off"
          value={this.state[filterName]}
          onChange={(e) => this.handleInputChange(e, filterName)}
          placeholder="Search..."
          label={filterValue}
          addonIconName="fa-search"
        />
      </div>
    );
  };

  render() {
    const { searchFilters, totalCount } = this.props;

    return (
      <Flexbox className="search-flex">
        {searchFilters.map((searchFilter) => this.renderSearchBar(searchFilter))}
        <div>Total Count: {totalCount}</div>
      </Flexbox>
    );
  }
}

export default SearchBarGroup;
