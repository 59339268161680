import ClientAddSearch from './Search/ClientAddSearch';
import { Card, LiteralText, DropdownButton } from '../../../components';
import namespaceTranslatedText from '../../TranslatedText';
import actionCreator from '../../../actioncreators/accountSettings/switchClients';
import Translator from '../../../utils/translation/Translator';
import { noop, searchConfig } from '../../../utils/constants';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pill } from '@idm/ui-components';

import './style.scss';

const TranslatedText = namespaceTranslatedText('ACCOUNT_SETTINGS.SWITCH_CLIENT');
const switchClientDictionary = new Translator('ACCOUNT_SETTINGS.SWITCH_CLIENT');

class UnwrappedSwitchClients extends React.Component {
  static propTypes = {
    associates: PropTypes.arrayOf(
      PropTypes.shape({
        associateId: PropTypes.string,
        clientId: PropTypes.string,
        isNexoClient: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isCurrentlySelectedAssociate: PropTypes.bool,
        isPersonalAssociate: PropTypes.bool,
        client: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      }).isRequired
    ).isRequired,
    isClientRebrandEnabled: PropTypes.bool,
    isLoadingData: PropTypes.bool.isRequired,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchLoadData: PropTypes.func.isRequired,
    dispatchSetActiveAssociate: PropTypes.func.isRequired,
    dispatchResetState: PropTypes.func.isRequired,
    isAllClientsServiceUser: PropTypes.bool,
    allClientsServiceUserTicketUrl: PropTypes.string,
  };

  static defaultProps = {
    isClientRebrandEnabled: false,
    isAllClientsServiceUser: false,
    allClientsServiceUserTicketUrl: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      isAddClientModalVisible: false,
    };
  }

  ClientAddSearchConfig = searchConfig.addClient;

  componentDidMount() {
    const { dispatchLoadData } = this.props;

    dispatchLoadData();
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  toggleAddClientModal = () => {
    const { isAddClientModalVisible } = this.state;

    this.setState({
      isAddClientModalVisible: !isAddClientModalVisible,
    });
  };

  getActionButtonMenuList = () => {
    const menuList = [];
    const { isAllClientsServiceUser, associates } = this.props;

    const lifionOpsAssociate = associates.find((associate) => associate.clientId === '002');

    if (isAllClientsServiceUser && lifionOpsAssociate) {
      menuList.push({
        key: 'addNewClientTask',
        label: 'Add New Client',
        onClick: this.toggleAddClientModal,
      });
    }

    return menuList;
  };

  onActionClick = (e, isNexoClient) => {
    const { dispatchSetActiveAssociate } = this.props;
    const newActiveAssociateId = e.target.getAttribute('data-meta-associate-id');
    const newActiveClientId = e.target.getAttribute('data-meta-client-id');

    dispatchSetActiveAssociate(newActiveAssociateId, newActiveClientId, isNexoClient);
  };

  toAssociateCardProps = (assoc) => {
    const { dictionary, isClientRebrandEnabled } = this.props;
    const isAssociateSuspended = assoc.suspended;
    const actionTextForActiveOrInactive = assoc.isCurrentlySelectedAssociate
      ? switchClientDictionary.get('ACTIVE', dictionary)
      : switchClientDictionary.get('SWITCH', dictionary, {
          clientName: assoc.client.name,
        });
    const actionText = isAssociateSuspended
      ? switchClientDictionary.get('SUSPENDED', dictionary)
      : actionTextForActiveOrInactive;
    const onActionClick =
      isAssociateSuspended || assoc.isCurrentlySelectedAssociate
        ? noop
        : (e) => this.onActionClick(e, assoc.isNexoClient);
    const className = isAssociateSuspended ? 'suspended' : '';

    return {
      avatarText: <LiteralText>{assoc.client.name[0]}</LiteralText>,
      mainText: <LiteralText>{assoc.client.name}</LiteralText>,
      actionText,
      active: assoc.isCurrentlySelectedAssociate,
      associateId: assoc.associateId,
      clientId: assoc.clientId,
      clientTypeText: (
        <Pill
          color={assoc.isNexoClient ? 'purple3' : 'koala'}
          pillText={
            <TranslatedText
              textKey={assoc.isNexoClient ? 'OLP_CLIENT_TYPE_LOGIN' : 'LIFION_CLIENT_TYPE_LOGIN'}
              isRebrandEnabled={isClientRebrandEnabled}
            />
          }
        />
      ),
      displayclientTypeText: !(assoc.isNexoClient === undefined),
      onActionClick,
      className,
    };
  };

  renderAssociateGroup() {
    const { associates } = this.props;
    const { isAddClientModalVisible } = this.state;

    if (associates.length === 0) {
      return null;
    }

    const associateCardProps = associates
      .sort((first, second) =>
        first.client.name.toLowerCase() > second.client.name.toLowerCase() ? 1 : -1
      )
      .map(this.toAssociateCardProps)
      .reverse()
      .sort((current) => (current.className === 'suspended' ? 1 : -1));

    const onlyOneClientInfo = associateCardProps.length === 1 && (
      <div className="only-client-info">
        <TranslatedText textKey="ONE_CLIENT" />
      </div>
    );
    const cards = associateCardProps.map((assoc) => <Card key={assoc.id} {...assoc} />);

    const menuList = this.getActionButtonMenuList();

    return (
      associateCardProps.length > 0 && (
        <div className="current-associates">
          {menuList.length > 0 && (
            <div className="action-container">
              <DropdownButton
                menuList={menuList}
                buttonType="cancel"
                buttonTitle="Actions"
                className="action-button"
              />
              <div className="search-bar-container">
                {isAddClientModalVisible && (
                  <ClientAddSearch
                    {...this.props}
                    ClientAddSearchConfig={this.ClientAddSearchConfig}
                    isVisible={isAddClientModalVisible}
                    onCancel={this.toggleAddClientModal}
                  />
                )}
              </div>
              <div className="dark-line" />
            </div>
          )}
          {cards}
          {onlyOneClientInfo}
        </div>
      )
    );
  }

  render() {
    const { isLoadingData } = this.props;

    if (isLoadingData) {
      return null;
    }

    return <div className="idm-switch-client-tab">{this.renderAssociateGroup()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    associates: state.accountSettingsSwitchClients.main.associates,
    isLoadingData: state.accountSettingsSwitchClients.loadData.isLoadingData,
    dictionary: state.translation.main.dictionary,
    isClientRebrandEnabled: state.me.main.isClientRebrandEnabled,
    isAllClientsServiceUser: state.me.main.isAllClientsServiceUser,
    allClientsServiceUserTicketUrl: state.me.main.allClientsServiceUserTicketUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchLoadData() {
      dispatch(actionCreator.loadData());
    },

    dispatchSetActiveAssociate(associateId, clientId, isNexoClient) {
      dispatch(actionCreator.setActiveAssociate(associateId, clientId, isNexoClient));
    },
  };
}

const SwitchClients = connect(mapStateToProps, mapDispatchToProps)(UnwrappedSwitchClients);

export { UnwrappedSwitchClients, SwitchClients as default };
