import Search from '.';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ClientAddSearch extends Component {
  static propTypes = {
    ClientAddSearchConfig: PropTypes.shape({
      searchFilters: PropTypes.array.isRequired,
      searchType: PropTypes.string.isRequired,
      limit: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    associates: PropTypes.arrayOf(
      PropTypes.shape({
        associateId: PropTypes.string,
        clientId: PropTypes.string,
        isNexoClient: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isCurrentlySelectedAssociate: PropTypes.bool,
        isPersonalAssociate: PropTypes.bool,
        client: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      }).isRequired
    ).isRequired,
    allClientsServiceUserTicketUrl: PropTypes.string.isRequired,
  };

  render() {
    const { ClientAddSearchConfig } = this.props;
    const { searchFilters, searchType, limit, title } = ClientAddSearchConfig;

    return (
      <Search
        {...this.props}
        title={title}
        searchType={searchType}
        searchFilters={searchFilters}
        limit={limit}
      />
    );
  }
}

export default ClientAddSearch;
