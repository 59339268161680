import './style.scss';
import warningIconPath from '../../../static/assets/images/warningIcon.png';
import TranslatedText from '../../components/elements/textDisplays/TranslatedText';
import PasswordCreationInput from '../../components/elements/formInputs/PasswordCreationInput';
import actionCreator from '../../actioncreators/accountSettings/changeUsername';
import actionTypes from '../../actiontypes';
import { getI18nContext } from '../TranslatedText';
import { UsernameRule } from '../../utils/validator/rules';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  TextInput,
  InlineMessage,
} from '../../components';
import SpinnerIcon from '../../../static/assets/images/static/spinner.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader, StandardParagraph, ValidatedInput } from '@idm/ui-components';
import { Form } from '@tbiegner99/react-forms';
import React from 'react';

const I18nKeys = {
  usernameLabel: 'OBLIGATION.CHANGE_USERNAME.USERNAME_LABEL',
  securityUpdate: 'OBLIGATION.CHANGE_USERNAME.SECURITY_UPDATE',
  singleIdTitle: 'OBLIGATION.CHANGE_USERNAME.SINGLE_ID.TITLE',
  multipleIdsTitle: 'OBLIGATION.CHANGE_USERNAME.MULTIPLE_IDS.TITLE',
  reviewSingleIdTitle: 'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.TITLE',
  reviewMultipleIdsTitle: 'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.TITLE',
  singleIdSubTitle: 'OBLIGATION.CHANGE_USERNAME.SINGLE_ID.SUBTITLE',
  multipleIdsSubTitleOne: 'OBLIGATION.CHANGE_USERNAME.MULTIPLE_IDS.SUBTITLE_PART_ONE',
  multipleIdsSubTitleTwo: 'OBLIGATION.CHANGE_USERNAME.MULTIPLE_IDS.SUBTITLE_PART_TWO',
  reviewSingleIdChangeSubTitle: 'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.CHANGE_SUBTITLE',
  reviewSingleIdNoChangeSubTitle1:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.NO_CHANGE_SUBTITLE_1',
  reviewSingleIdNoChangeSubTitle2:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.NO_CHANGE_SUBTITLE_2',
  reviewSingleIdNoChangeSubTitle3:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.NO_CHANGE_SUBTITLE_3',
  reviewMultipleIdsSubTitle1: 'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.SUBTITLE_1',
  reviewMultipleIdsChangeSubTitle2:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.CHANGE_SUBTITLE_2',
  reviewMultipleIdsNoChangeSubTitle2:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.NO_CHANGE_SUBTITLE_2',
  reviewMultipleIdsNoChangeSubTitle3:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.NO_CHANGE_SUBTITLE_3',
  reviewMultipleIdsNoChangeSubTitle4:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.NO_CHANGE_SUBTITLE_4',
  reviewSingleIdChangeMessage: 'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.CHANGE_MESSAGE',
  reviewSingleIdNoChangeMessage: 'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.NO_CHANGE_MESSAGE',
  reviewMultipleIdsMessage1: 'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.MESSAGE_1',
  reviewMultipleIdsChangeMessage2:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.CHANGE_MESSAGE_2',
  reviewMultipleIdsNoChangeMessage2:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.NO_CHANGE_MESSAGE_2',
  reviewSingleIdChangeWarnMessage1: 'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.WARNING_MESSAGE_1',
  reviewSingleIdChangeWarnMessage2: 'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.WARNING_MESSAGE_2',
  reviewSingleIdChangeWarnMessage3: 'OBLIGATION.CHANGE_USERNAME.REVIEW_SINGLE_ID.WARNING_MESSAGE_3',
  reviewMultipleIdsChangeWarnMessage1:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.WARNING_MESSAGE_1',
  reviewMultipleIdsChangeWarnMessage2:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.WARNING_MESSAGE_2',
  reviewMultipleIdsChangeWarnMessage3:
    'OBLIGATION.CHANGE_USERNAME.REVIEW_MULTIPLE_IDS.WARNING_MESSAGE_3',
  continueButton: 'OBLIGATION.CHANGE_USERNAME.CONTINUE_BUTTON',
  createUserIdsButton: 'OBLIGATION.CHANGE_USERNAME.CREATE_USER_IDS_BUTTON',
  createUserIdButton: 'OBLIGATION.CHANGE_USERNAME.CREATE_USER_ID_BUTTON',
  submitButton: 'OBLIGATION.CHANGE_USERNAME.SUBMIT_BUTTON',
  backButton: 'OBLIGATION.CHANGE_USERNAME.BACK_BUTTON',
  updateSuccess: 'OBLIGATION.CHANGE_USERNAME.UPDATE_SUCCESS',
  updateFailure: 'OBLIGATION.CHANGE_USERNAME.ERROR',
  currentUsername: 'OBLIGATION.CHANGE_USERNAME.CURRENT_USERNAME',
  textInputError: 'TEXT_INPUT.ERROR_MESSAGE',
  newPasswordLabel: 'OBLIGATION.CHANGE_USERNAME.PASSWORD_LABEL',
};

class ChangeUsernameObligationPage extends React.Component {
  static propTypes = {
    i18nContext: PropTypes.shape({ getRawTextForKey: PropTypes.func.isRequired }).isRequired,
    dispatchOnSubmit: PropTypes.func.isRequired,
    dispatchOnReviewUsernameContinue: PropTypes.func.isRequired,
    associateUserNameDetails: PropTypes.array,
    isReviewUsernameObligation: PropTypes.bool,
    isNotAllowUpdateUsername: PropTypes.bool,
    currentUsername: PropTypes.string,
    isUsernameUnique: PropTypes.bool,
    error: PropTypes.object,
    isErrorVisible: PropTypes.bool,
    usernameInputs: PropTypes.array,
    username: PropTypes.string.isRequired,
    isLoadingConflictingUsernames: PropTypes.bool.isRequired,
    isLoadingCurrentUsername: PropTypes.bool.isRequired,
    isCheckingUsernameUnique: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isReviewSubmitting: PropTypes.bool.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSetFormFieldValidationResult: PropTypes.func.isRequired,
    dispatchSetFormIsValidating: PropTypes.func.isRequired,
    dispatchCheckUsernameUnique: PropTypes.func.isRequired,
    dispatchLoadData: PropTypes.func.isRequired,
    dispatchResetState: PropTypes.func.isRequired,
    dispatchResetError: PropTypes.func.isRequired,
    userNameRuleViolationMsg: PropTypes.string,
  };

  static defaultProps = {
    associateUserNameDetails: [],
    isReviewUsernameObligation: false,
    isNotAllowUpdateUsername: false,
    currentUsername: '',
    isUsernameUnique: true,
    usernameInputs: [],
    isErrorVisible: false,
    error: {
      code: undefined,
      message: undefined,
    },
    userNameRuleViolationMsg:
      'Usernames must be 5 to 40 characters long and can only include letters, numbers, underscores (_), hyphens (-), at signs (@), and periods (.). Periods cannot be consecutive.',
  };

  constructor(props) {
    super(props);
    this.inputs = [];
    this.state = {
      showEducationalMessage: true,
      showSystemGeneratedUserForm: false,
    };
    this.handleSubmit = this.submit.bind(this);
  }

  componentDidMount() {
    const { dispatchLoadData } = this.props;

    dispatchLoadData();
  }

  onContinueEducationalPopup = () => {
    const { isReviewUsernameObligation } = this.props;

    this.setState({ showEducationalMessage: false });
    this.setState({ showSystemGeneratedUserForm: isReviewUsernameObligation });
  };

  onContinueSystemGeneratedUserFormPopup = async () => {
    const { associateUserNameDetails, dispatchOnReviewUsernameContinue } = this.props;

    const mappedFormData = Object.entries(associateUserNameDetails).map(([, value]) => {
      const { clientId, associateId, userId: username } = value;

      return {
        clientId,
        associateId,
        username,
      };
    });

    try {
      dispatchOnReviewUsernameContinue({
        associateUsernames: mappedFormData,
      });
    } catch (err) {
      // no-op
    }
  };

  onCreateUserIdFormPopup = () => {
    this.setState({ showSystemGeneratedUserForm: false });
  };

  onCancelChangeUserForm = () => {
    const { dispatchResetError } = this.props;

    dispatchResetError();
    const { isReviewUsernameObligation } = this.props;

    this.setState({ showEducationalMessage: !isReviewUsernameObligation });
    this.setState({ showSystemGeneratedUserForm: isReviewUsernameObligation });
  };

  onCancelSystemGeneratedUserForm = () => {
    const { dispatchResetError } = this.props;

    dispatchResetError();

    this.setState({ showEducationalMessage: true });
    this.setState({ showSystemGeneratedUserForm: false });
  };

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  handleKeyPress = (event) => {
    const { dispatchSetFormIsValidating } = this.props;

    if (event.key === 'Enter') {
      dispatchSetFormIsValidating();
    }
  };

  checkUsernameConflict = async (inputUsername) => {
    const { usernameInputs, username } = this.props;
    const usernameInputsExcludingPassword = Object.fromEntries(
      Object.entries(usernameInputs).filter(([field]) => !field.includes(':password'))
    );

    const isUserNameDuplicatedWithinUser =
      usernameInputsExcludingPassword &&
      Object.values(usernameInputsExcludingPassword)
        .map((i) => i.value)
        .filter((name) => name === inputUsername).length > 1;

    const isSameAsCurrentUsername =
      usernameInputsExcludingPassword &&
      Object.values(usernameInputsExcludingPassword)
        .map((i) => i.value)
        .filter((name) => name === username).length > 0;

    if (isUserNameDuplicatedWithinUser || isSameAsCurrentUsername) {
      throw Error;
    }
  };

  checkUsernameUnique = async (inputUsername) => {
    const { dispatchCheckUsernameUnique } = this.props;

    await dispatchCheckUsernameUnique(inputUsername);

    const { isUsernameUnique, isCheckingUsernameUnique } = this.props;

    if (!isCheckingUsernameUnique && !isUsernameUnique) {
      throw Error;
    }
  };

  getRules = (inputUsername, suggestedUsername) => {
    const { i18nContext, userNameRuleViolationMsg } = this.props;
    const rules = [
      new UsernameRule(userNameRuleViolationMsg),
      new ValidatedInput.CustomRule(
        this.checkUsernameConflict,
        i18nContext.getRawTextForKey('ERROR')
      ),
    ];

    if (suggestedUsername !== inputUsername) {
      rules.push(
        new ValidatedInput.CustomRule(
          this.checkUsernameUnique,
          i18nContext.getRawTextForKey('ERROR_USERNAME_UNAVAILABLE')
        )
      );
    }

    return rules;
  };

  handleValidationChange(field, validationResult) {
    const { dispatchSetFormFieldValidationResult } = this.props;

    dispatchSetFormFieldValidationResult(field, validationResult);
  }

  handleChange(field, event) {
    const { dispatchSetFormField, isErrorVisible, dispatchResetError } = this.props;

    if (isErrorVisible) {
      dispatchResetError();
    }
    dispatchSetFormField(field, event.target.value);
  }

  handlePasswordChange(field, value) {
    const { dispatchSetFormField, isErrorVisible, dispatchResetError } = this.props;

    if (isErrorVisible) {
      dispatchResetError();
    }
    dispatchSetFormField(field, value);
  }

  submit = async () => {
    const { usernameInputs, dispatchOnSubmit } = this.props;

    const assertPromises = this.inputs.map((input) => input.assertValid());

    const usernameInputsExcludingPassword = Object.fromEntries(
      Object.entries(usernameInputs).filter(([field]) => !field.includes(':password'))
    );

    const mappedFormData = Object.entries(usernameInputsExcludingPassword).map(([field, input]) => {
      const [clientId, associateId] = field.split(':');

      return {
        clientId,
        associateId,
        username: input.value,
        password: usernameInputs[`${field}:password`].value,
      };
    });

    try {
      await Promise.all(assertPromises);
      dispatchOnSubmit({ associateUsernames: mappedFormData });
    } catch (err) {
      // no-op
    }
  };

  handleUsernameChange = (field) => this.handleChange.bind(this, field);

  handlePasswordChangeEvent = (field) => this.handlePasswordChange.bind(this, field);

  handleFieldValidationChange = (field) => this.handleValidationChange.bind(this, field);

  handleUsernameKeyPress = () => this.handleKeyPress.bind(this);

  setRef = (obj) => {
    this.inputs.push(obj);
  };

  renderChangeUsernameInput() {
    const { usernameInputs, associateUserNameDetails, i18nContext, dispatchSetFormField } =
      this.props;

    const usernameInputsExcludingPassword = Object.fromEntries(
      Object.entries(usernameInputs).filter(([field]) => !field.includes(':password'))
    );

    const ismultipleClients = Object.keys(usernameInputsExcludingPassword).length > 1;

    return Object.entries(usernameInputsExcludingPassword).map(([field, input], index) => {
      const passwordKey = `${field}:password`;
      const [clientId, associateId] = field.split(':');
      const { clientName, userId } = associateUserNameDetails.find(
        (d) => d.clientId === clientId && d.associateId === associateId
      );

      return (
        <div className="usernameInput" key={`div-username-input-${field}`}>
          {ismultipleClients && (
            <div>
              <div className="clientNameTitle">{`${index + 1}. ${clientName}`}</div>
            </div>
          )}
          <div className="usernameBlock">
            <div className="lileft">
              <div key={`span-username-input-${field}`} className="suggestedIdLabel">
                Suggested User ID
              </div>
              <div className="suggestedUserId">{userId}</div>
            </div>
            <div className="copyButton">
              <span
                className="selectText"
                role="button"
                tabIndex={0}
                onClick={() => dispatchSetFormField(field, userId)}
              >
                Select
              </span>
            </div>
          </div>
          <div>
            <TextInput
              label={i18nContext.getRawTextForKey('USERNAME_LABEL')}
              type="text"
              className="input-label"
              key={field}
              value={input.value}
              id={field}
              name={field}
              onChange={this.handleUsernameChange(field)}
              handleValidationChange={this.handleFieldValidationChange(field)}
              validateOnBlur={true}
              additionalRules={this.getRules(input.value, userId)}
              setRef={this.setRef}
            />
            <PasswordCreationInput
              data-meta-id="password"
              data-msg-required={<TranslatedText i18nKey={I18nKeys.textInputError} />}
              showRequirements={true}
              key={passwordKey}
              id={passwordKey}
              value={usernameInputs[passwordKey].value}
              label={<TranslatedText className="input-label" i18nKey={I18nKeys.newPasswordLabel} />}
              name={passwordKey}
              autoComplete="new-password"
              onChange={this.handlePasswordChangeEvent(passwordKey)}
              onValidate={this.handleFieldValidationChange(passwordKey)}
              validateOnChange={true}
            />
          </div>
          <div className="darkLine" />
        </div>
      );
    });
  }

  renderSystemGenUsername() {
    const { currentUsername, usernameInputs, associateUserNameDetails } = this.props;

    const usernameInputsExcludingPassword = Object.fromEntries(
      Object.entries(usernameInputs).filter(([field]) => !field.includes(':password'))
    );

    const isMultipleClients = Object.keys(usernameInputsExcludingPassword).length > 1;

    return Object.entries(usernameInputsExcludingPassword).map(([field], index) => {
      const [clientId, associateId] = field.split(':');
      const userDetail = associateUserNameDetails.find(
        (detail) => detail.clientId === clientId && detail.associateId === associateId
      );

      if (!userDetail) return null;

      const { clientName, userId } = userDetail;

      return (
        <div className="systemGenUsernameInput" key={`system-gen-username-${field}`}>
          {isMultipleClients && (
            <div className="clientHeader">
              <div className="clientName">{`${index + 1}. ${clientName}`}</div>
            </div>
          )}

          {!isMultipleClients && (
            <div className="clientHeader">
              <div className="clientName">{`${clientName}`}</div>
            </div>
          )}

          <div className="fieldLabel">Username (Current)</div>
          <div className="fieldValue">{currentUsername}</div>

          <div className="fieldLabel">User ID (New)</div>
          <div className="fieldValue">{userId}</div>

          <div className="darkLine" />
        </div>
      );
    });
  }

  renderChangeUsernameFormButtons() {
    const { isSubmitting, usernameInputs } = this.props;
    const isInputEmpty = Object.values(usernameInputs)
      .map((input) => input.value.trim() === '')
      .includes(true);

    return (
      <div className="buttonRow">
        <SecondaryButton data-meta-id="Cancel" onClick={this.onCancelChangeUserForm}>
          <TranslatedText i18nKey={I18nKeys.backButton} />
        </SecondaryButton>
        <PrimaryButton data-meta-id="Submit" type="submit" disabled={isSubmitting || isInputEmpty}>
          {isSubmitting ? (
            <div className="loader" data-meta-id="username-page-loader">
              <img src={SpinnerIcon} alt="Spinner Icon" />
            </div>
          ) : (
            <TranslatedText i18nKey={I18nKeys.submitButton} />
          )}
        </PrimaryButton>
      </div>
    );
  }

  renderChangeUsernameForm() {
    return (
      <Form id="change-username-form" onSubmit={this.handleSubmit}>
        {this.renderChangeUsernameInput()}
        {this.renderChangeUsernameFormButtons()}
      </Form>
    );
  }

  renderSystemGenUsernameForm() {
    const { associateUserNameDetails } = this.props;

    const numberOfClients = associateUserNameDetails?.length;

    const isMultiAssociateUser = numberOfClients > 1;
    const singleIdWarnMessage1 = I18nKeys.reviewSingleIdChangeWarnMessage1;
    const singleIdWarnMessage2 = I18nKeys.reviewSingleIdChangeWarnMessage2;
    const singleIdWarnMessage3 = I18nKeys.reviewSingleIdChangeWarnMessage3;
    const multipleIdsWarnMessage1 = I18nKeys.reviewMultipleIdsChangeWarnMessage1;
    const multipleIdsWarnMessage2 = I18nKeys.reviewMultipleIdsChangeWarnMessage2;
    const multipleIdsWarnMessage3 = I18nKeys.reviewMultipleIdsChangeWarnMessage3;

    return (
      <Form id="system-username-form" onSubmit={this.handleSubmit}>
        {this.renderSystemGenUsername()}
        <div className="warningMessage">
          <img alt="Warning Icon" className="warningIcon" src={warningIconPath} />
          <span className="warningText">
            <TranslatedText
              i18nKey={isMultiAssociateUser ? multipleIdsWarnMessage1 : singleIdWarnMessage1}
            />
            <strong>
              {' '}
              <TranslatedText
                i18nKey={isMultiAssociateUser ? multipleIdsWarnMessage2 : singleIdWarnMessage2}
              />
            </strong>

            <TranslatedText
              i18nKey={isMultiAssociateUser ? multipleIdsWarnMessage3 : singleIdWarnMessage3}
            />
          </span>
        </div>
      </Form>
    );
  }

  render() {
    const {
      i18nContext,
      associateUserNameDetails,
      isLoadingConflictingUsernames,
      isLoadingCurrentUsername,
      isErrorVisible,
      error,
      isReviewUsernameObligation,
      isNotAllowUpdateUsername,
      isReviewSubmitting,
    } = this.props;

    const { showEducationalMessage, showSystemGeneratedUserForm } = this.state;
    const numberOfClients = associateUserNameDetails?.length;

    const isMultiAssociateUser = numberOfClients > 1;
    const singleClientName = associateUserNameDetails[0]
      ? associateUserNameDetails[0].clientName
      : '';

    if (isLoadingConflictingUsernames || isLoadingCurrentUsername) {
      return (
        <div className="loader" data-meta-id="username-page-loader">
          <img src={SpinnerIcon} alt="Spinner Icon" />
        </div>
      );
    }

    const isChangeUsernameForm = !showEducationalMessage && !showSystemGeneratedUserForm;

    let multipleIdsTitle;
    let singleIdTitle;
    let singleIdSubTitle1 = '';
    let singleIdSubTitle2 = '';
    let singleIdSubTitle3 = '';
    let multipleIdsSubTitle1 = '';
    let multipleIdsSubTitle2 = '';
    let multipleIdsSubTitle3 = '';
    let multipleIdsSubTitle4 = '';

    let singleIdMessage = '';
    let multipleIdsMessage1 = '';
    let multipleIdsMessage2 = '';

    if (!isReviewUsernameObligation && !isNotAllowUpdateUsername) {
      singleIdSubTitle1 = I18nKeys.singleIdSubTitle;
      multipleIdsSubTitle1 = I18nKeys.multipleIdsSubTitleOne;
      multipleIdsSubTitle2 = I18nKeys.multipleIdsSubTitleTwo;
    }
    if (isReviewUsernameObligation && isNotAllowUpdateUsername) {
      singleIdSubTitle1 = I18nKeys.reviewSingleIdNoChangeSubTitle1;
      singleIdSubTitle2 = I18nKeys.reviewSingleIdNoChangeSubTitle2;
      singleIdSubTitle3 = I18nKeys.reviewSingleIdNoChangeSubTitle3;
      multipleIdsSubTitle1 = I18nKeys.reviewMultipleIdsSubTitle1;
      multipleIdsSubTitle2 = I18nKeys.reviewMultipleIdsNoChangeSubTitle2;
      multipleIdsSubTitle3 = I18nKeys.reviewMultipleIdsNoChangeSubTitle3;
      multipleIdsSubTitle4 = I18nKeys.reviewMultipleIdsNoChangeSubTitle4;

      singleIdMessage = I18nKeys.reviewSingleIdNoChangeMessage;
      multipleIdsMessage1 = I18nKeys.reviewMultipleIdsMessage1;
      multipleIdsMessage2 = I18nKeys.reviewMultipleIdsNoChangeMessage2;
    }
    if (isReviewUsernameObligation && !isNotAllowUpdateUsername) {
      singleIdSubTitle1 = I18nKeys.reviewSingleIdChangeSubTitle;
      multipleIdsSubTitle1 = I18nKeys.reviewMultipleIdsSubTitle1;
      multipleIdsSubTitle2 = I18nKeys.reviewMultipleIdsChangeSubTitle2;

      singleIdMessage = I18nKeys.reviewSingleIdChangeMessage;
      multipleIdsMessage1 = I18nKeys.reviewMultipleIdsMessage1;
      multipleIdsMessage2 = I18nKeys.reviewMultipleIdsChangeMessage2;
    }

    if (isChangeUsernameForm) {
      multipleIdsTitle = I18nKeys.multipleIdsTitle;
      singleIdTitle = I18nKeys.singleIdTitle;
    } else {
      multipleIdsTitle = I18nKeys.reviewMultipleIdsTitle;
      singleIdTitle = I18nKeys.reviewSingleIdTitle;
    }

    return (
      <div className="change-username">
        {isErrorVisible && <InlineMessage type="error">{error.message}</InlineMessage>}
        <header>
          {showEducationalMessage && !showSystemGeneratedUserForm && (
            <PageHeader className="titleHeader">
              <TranslatedText i18nKey={I18nKeys.securityUpdate} />
            </PageHeader>
          )}
          {showEducationalMessage && !showSystemGeneratedUserForm && (
            <StandardParagraph className="educational-message-text">
              <TranslatedText
                i18nKey={isMultiAssociateUser ? multipleIdsSubTitle1 : singleIdSubTitle1}
              />
              {isMultiAssociateUser && <span>{numberOfClients} </span>}
              {isMultiAssociateUser ? (
                <TranslatedText i18nKey={multipleIdsSubTitle2} />
              ) : (
                <strong>
                  <TranslatedText i18nKey={singleIdSubTitle2} />
                </strong>
              )}
              {isMultiAssociateUser ? (
                <strong>
                  <TranslatedText i18nKey={multipleIdsSubTitle3} />
                </strong>
              ) : (
                <TranslatedText i18nKey={singleIdSubTitle3} />
              )}
              {isMultiAssociateUser && <TranslatedText i18nKey={multipleIdsSubTitle4} />}
              <br />
            </StandardParagraph>
          )}
          {!showEducationalMessage && showSystemGeneratedUserForm && (
            <PageHeader className="titleHeader">
              <TranslatedText i18nKey={isMultiAssociateUser ? multipleIdsTitle : singleIdTitle} />
            </PageHeader>
          )}
          {!showEducationalMessage && showSystemGeneratedUserForm && (
            <div className="review-username-message-text">
              <TranslatedText
                i18nKey={isMultiAssociateUser ? multipleIdsMessage1 : singleIdMessage}
              />
              {isMultiAssociateUser && <span>{numberOfClients} </span>}
              {isMultiAssociateUser && <TranslatedText i18nKey={multipleIdsMessage2} />}
              <br />
            </div>
          )}
          {!showEducationalMessage && !showSystemGeneratedUserForm && (
            <PageHeader className="titleHeader">
              <TranslatedText i18nKey={isMultiAssociateUser ? multipleIdsTitle : singleIdTitle} />
            </PageHeader>
          )}
        </header>
        <main>
          {!showEducationalMessage &&
            !showSystemGeneratedUserForm &&
            isMultiAssociateUser &&
            !isNotAllowUpdateUsername && (
              <div className="clientMainSubtitle">Enter a new User ID for each client:</div>
            )}
          {!showEducationalMessage &&
            !showSystemGeneratedUserForm &&
            !isMultiAssociateUser &&
            !isNotAllowUpdateUsername && (
              <div className="clientMainSubtitle">Enter a new User ID for {singleClientName}:</div>
            )}
          {!showEducationalMessage &&
            showSystemGeneratedUserForm &&
            associateUserNameDetails &&
            this.renderSystemGenUsernameForm(i18nContext)}
          {!showEducationalMessage &&
            !showSystemGeneratedUserForm &&
            !isNotAllowUpdateUsername &&
            associateUserNameDetails &&
            this.renderChangeUsernameForm(i18nContext)}
        </main>
        <footer>
          {showEducationalMessage && (
            <ButtonContainer className="continueButton">
              <SecondaryButton
                data-meta-id="continue"
                onClick={this.onContinueEducationalPopup}
                disabled={isErrorVisible}
              >
                <TranslatedText i18nKey={I18nKeys.continueButton} />
              </SecondaryButton>
            </ButtonContainer>
          )}
          {showSystemGeneratedUserForm && (
            <ButtonContainer className="continueButton">
              <SecondaryButton
                data-meta-id="Cancel"
                disabled={isErrorVisible || isReviewSubmitting}
                onClick={this.onCancelSystemGeneratedUserForm}
              >
                <TranslatedText i18nKey={I18nKeys.backButton} />
              </SecondaryButton>
              {!isNotAllowUpdateUsername && (
                <SecondaryButton
                  data-meta-id="createUserIdButton"
                  onClick={this.onCreateUserIdFormPopup}
                  disabled={isErrorVisible || isReviewSubmitting}
                >
                  <TranslatedText
                    i18nKey={
                      isMultiAssociateUser
                        ? I18nKeys.createUserIdsButton
                        : I18nKeys.createUserIdButton
                    }
                  />
                </SecondaryButton>
              )}
              <PrimaryButton
                data-meta-id="continue"
                onClick={this.onContinueSystemGeneratedUserFormPopup}
                type="submit"
                disabled={isErrorVisible || isReviewSubmitting}
              >
                {isReviewSubmitting ? (
                  <div className="loader" data-meta-id="username-page-loader">
                    <img src={SpinnerIcon} alt="Spinner Icon" />
                  </div>
                ) : (
                  <TranslatedText i18nKey={I18nKeys.continueButton} />
                )}
              </PrimaryButton>
            </ButtonContainer>
          )}
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { translation, accountSettingsChangeUsername } = state;
  const {
    associateUserNameDetails,
    isUsernameUnique,
    username,
    isLoadingConflictingUsernames,
    isLoadingCurrentUsername,
    isCheckingUsernameUnique,
    isSubmitting,
    isReviewSubmitting,
    isErrorVisible,
    error,
    isReviewUsernameObligation,
    isNotAllowUpdateUsername,
    currentUsername,
  } = accountSettingsChangeUsername.main;
  const { dictionary } = translation.main;
  const i18nContext = getI18nContext('OBLIGATION.CHANGE_USERNAME', dictionary);

  return {
    i18nContext,
    associateUserNameDetails,
    isUsernameUnique,
    username,
    isLoadingConflictingUsernames,
    isLoadingCurrentUsername,
    isCheckingUsernameUnique,
    isSubmitting,
    isReviewSubmitting,
    isErrorVisible,
    error,
    usernameInputs: accountSettingsChangeUsername.form,
    isReviewUsernameObligation,
    isNotAllowUpdateUsername,
    currentUsername,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async dispatchLoadData() {
      await dispatch(actionCreator.loadData());
      await dispatch(actionCreator.getConflictingUsernames());
    },

    dispatchSetFormFieldValidationResult(field, validationResult) {
      dispatch(actionCreator.setFormFieldValidationResult(field, validationResult));
    },

    dispatchSetFormIsValidating() {
      dispatch(actionCreator.setFormIsValidating());
    },

    async dispatchCheckUsernameUnique(inputUsername) {
      await dispatch(actionCreator.checkUsernameUnique(inputUsername));
    },

    dispatchResetState() {
      dispatch(actionCreator.resetMainState());
    },

    dispatchResetError() {
      dispatch({
        type: actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.RESET_ERROR,
      });
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    async dispatchOnSubmit(data) {
      dispatch(actionCreator.setFormIsValidating());
      await dispatch(actionCreator.updateUsername(data));
    },

    async dispatchOnReviewUsernameContinue(data) {
      await dispatch(actionCreator.reviewUsername(data));
    },
  };
}

export {
  mapDispatchToProps,
  ChangeUsernameObligationPage as UnwrappedChangeUsernameObligationPage,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeUsernameObligationPage);
