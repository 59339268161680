import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  personId: undefined,
  firstName: '',
  lastName: '',
  email: '',
  isEmailVerified: false,
  phone: {
    number: '',
    countryCode: undefined,
  },
  clientId: '',
  clientName: '',
  isCheckingAuth: false,
  isAuthenticated: null,
  isLoggingOut: false,
  isRebrandEnabled: false,
  isClientRebrandEnabled: false,
  isAllClientsServiceUser: false,
  allClientsServiceUserTicketUrl: '',
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ME.CHECK_AUTH_FAILURE:
      return {
        ...state,
        isCheckingAuth: false,
        isAuthenticated: false,
      };
    case actionTypes.ME.CHECK_AUTH_SUCCESS:
      return {
        ...state,
        isCheckingAuth: false,
        isAuthenticated: true,
        isAuthenticatedByNexo: actionPayload?.isAuthenticatedByNexo || false,
        levelOfAssurance: actionPayload?.levelOfAssurance,
      };
    case actionTypes.ME.CHECK_AUTH_REQUEST:
      return { ...state, isCheckingAuth: true };
    case actionTypes.ME.CHECK_CLIENT_REBRAND_FAILURE:
      return {
        ...state,
        isRebrandEnabled: false,
      };
    case actionTypes.ME.CHECK_CLIENT_REBRAND_SUCCESS:
      return {
        ...state,
        isRebrandEnabled: actionPayload?.clientRebrandEnabledStatus,
      };
    case actionTypes.ME.CHECK_CLIENT_REBRAND_REQUEST:
      return { ...state };

    case actionTypes.ME.LOGOUT_FAILURE:
    case actionTypes.ME.LOGOUT_ALL_SESSIONS_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
      };
    case actionTypes.ME.LOGOUT_SUCCESS:
    case actionTypes.ME.LOGOUT_ALL_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
      };
    case actionTypes.ME.LOGOUT_REQUEST:
    case actionTypes.ME.LOGOUT_ALL_SESSIONS_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
      };
    case actionTypes.ME.SET_DATA:
      return {
        ...state,
        ...actionPayload,
      };
    default:
      return state;
  }
}

const meReducer = combineReducers({
  main: mainReducer,
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.ME),
});

export { meReducer as default };
