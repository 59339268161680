import buildFormReducer from 'reducers/builders/form';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const initialState = {
  error: {
    message: undefined,
    code: undefined,
  },
  isErrorVisible: false,
  isLoadingCurrentUsername: false,
  isLoadingConflictingUsernames: false,
  isCheckingUsernameUnique: false,
  isSubmitting: false,
  isReviewSubmitting: false,
  isReviewUsernameObligation: false,
  isNotAllowUpdateUsername: false,
  currentUsername: '',
};

const formInitialState = {};

function mainReducer(state = initialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.LOAD_SUCCESS:
      return {
        ...state,
        username: actionPayload?.username,
        isLoadingCurrentUsername: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.LOAD_FAILURE:
      return {
        ...state,
        error: {
          message: actionPayload?.message,
          code: actionPayload?.status,
        },
        isErrorVisible: true,
        isLoadingCurrentUsername: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.LOAD_REQUEST:
      return {
        ...state,
        error: {
          message: undefined,
          code: undefined,
        },
        isErrorVisible: false,
        isLoadingCurrentUsername: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.GET_USERNAME_SUCCESS:
      return {
        ...state,
        associateUserNameDetails: actionPayload?.associateUserNameDetails?.associates,
        isReviewUsernameObligation:
          actionPayload?.associateUserNameDetails?.isReviewUsernameObligation,
        isNotAllowUpdateUsername: actionPayload?.associateUserNameDetails?.isNotAllowUpdateUsername,
        currentUsername: actionPayload?.associateUserNameDetails?.username,
        isLoadingConflictingUsernames: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.GET_USERNAME_REQUEST:
      return {
        ...state,
        error: {
          message: undefined,
          code: undefined,
        },
        isErrorVisible: false,
        isLoadingConflictingUsernames: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.GET_USERNAME_ERROR:
      return {
        ...state,
        error: {
          message: actionPayload?.message,
          code: actionPayload?.status,
        },
        isErrorVisible: true,
        isLoadingConflictingUsernames: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHECK_USERNAME_UNIQUE_SUCCESS:
      return {
        ...state,
        isUsernameUnique: actionPayload,
        isCheckingUsernameUnique: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHECK_USERNAME_UNIQUE_REQUEST:
      return {
        ...state,
        isUsernameUnique: true,
        isCheckingUsernameUnique: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHECK_USERNAME_UNIQUE_ERROR:
      return {
        ...state,
        isUsernameUnique: actionPayload,
        isCheckingUsernameUnique: false,
      };

    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHANGE_USERNAME_ERROR:
      return {
        ...state,
        error: {
          message: actionPayload?.message,
          code: actionPayload?.status,
        },
        isErrorVisible: true,
        isSubmitting: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHANGE_USERNAME_REQUEST:
      return {
        ...state,
        error: {
          message: undefined,
          code: undefined,
        },
        isErrorVisible: false,
        isSubmitting: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.REVIEW_USERNAME_SUCCESS:
      return {
        ...state,
        isReviewSubmitting: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.REVIEW_USERNAME_ERROR:
      return {
        ...state,
        error: {
          message: actionPayload?.message,
          code: actionPayload?.status,
        },
        isErrorVisible: true,
        isReviewSubmitting: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.REVIEW_USERNAME_REQUEST:
      return {
        ...state,
        error: {
          message: undefined,
          code: undefined,
        },
        isErrorVisible: false,
        isReviewSubmitting: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.RESET_STATE:
      return { ...initialState };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.RESET_ERROR:
      return {
        ...state,
        error: {
          message: undefined,
          code: undefined,
        },
        isErrorVisible: false,
      };
    default:
      return state;
  }
}

const changeUsernameReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.ACCOUNT_SETTINGS_CHANGE_USERNAME),
});

export { changeUsernameReducer as default };
